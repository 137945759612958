import _ from 'lodash'
import axios from 'axios'
import FieldAdresse from '../../maps/field_adresse.vue'
import FlexiblePolyline from '../../maps/flexible-polyline.js'
import ModaleGauche from '../../modals/modale_gauche.vue'

export default {
	data() {
		return {
			vehicule: 	this.$store.state.ui.map_itineraire_result && this.$store.state.ui.map_itineraire_result.vehicule ? 	this.$store.state.ui.map_itineraire_result.vehicule : 	'truck',
			depart: 	this.$store.state.ui.map_itineraire_result && this.$store.state.ui.map_itineraire_result.depart ? 		this.$store.state.ui.map_itineraire_result.depart : 	null,
			arrivee: 	this.$store.state.ui.map_itineraire_result && this.$store.state.ui.map_itineraire_result.arrivee ? 		this.$store.state.ui.map_itineraire_result.arrivee : 	null,
			type: 		this.$store.state.ui.map_itineraire_result && this.$store.state.ui.map_itineraire_result.type ? 		this.$store.state.ui.map_itineraire_result.type : 		'fast',
			itineraire: this.$store.state.ui.map_itineraire_result && this.$store.state.ui.map_itineraire_result.itineraire ? 	this.$store.state.ui.map_itineraire_result.itineraire : null,
		}
	},
	props: [],
	watch: {
		depart: function(after, before){
			this.itineraire = null
			this.updateGeoJson()
		},
		arrivee: function(after, before){
			this.itineraire = null
			this.updateGeoJson()
		},
	},
	created(){
		
	},
	mounted(){

	},
	methods: {
		send_request(){
			if(!this.isOk) return

			let self = this
			let transportMode = this.vehicule == 'truck' ? 'truck' : this.vehicule
			let params = [
				'transportMode='+transportMode,
				'routingMode='+this.type,
				'origin='+this.depart.geometry.location.lat()+','+this.depart.geometry.location.lng(),
				'destination='+this.arrivee.geometry.location.lat()+','+this.arrivee.geometry.location.lng(),
				'return=polyline,summary'
			]
			params = params.join('---')

			axios.get('/plugin_projet/plugin_projet_iannuaire/here/router.hereapi.com---v8---routes/'+params)
				.then(function (response) {
					self.itineraire = response.data
					self.updateGeoJson()
					
				}).catch(function (error) {

				})
		},
		updateGeoJson(){
			let features = []

			if(this.itineraire && this.itineraire.routes){

				let polyline = FlexiblePolyline.decode(this.itineraire.routes[0].sections[0].polyline).polyline

				let polyline_inv = []
				polyline.forEach(function(point){
					polyline_inv.push([point[1], point[0]])
				})

				features.push({
					"type": "Feature",
					"geometry": {
						"type": "LineString",
						"coordinates": polyline_inv
					},
					"properties" : {}
				})
			}

			//AS--> Markers : https://sites.google.com/site/gmapsdevelopment/
			if(this.depart)
				features.push({
					"type": "Feature",
					"geometry": {
						"type": "Point",
						"coordinates": [this.depart.geometry.location.lng(), this.depart.geometry.location.lat()]
					},
					"properties": {
						"style" : {
							"icon" : {
								"url" : "http://maps.google.com/mapfiles/ms/icons/blue.png"
							}
						}
					}
				})

			if(this.arrivee)
				features.push({
					"type": "Feature",
					"geometry": {
						"type": "Point",
						"coordinates": [this.arrivee.geometry.location.lng(), this.arrivee.geometry.location.lat()]
					},
					"properties": {
						"style" : {
							"icon" : {
								"url" : "http://maps.google.com/mapfiles/ms/icons/green.png"
							}
						}
					}
				})

			if(features.length){
				let geojson = {
					"type": "FeatureCollection",
					"features": features,
					"properties": {
						"style": {
							"strokeColor": '#07CE6F',
							"strokeOpacity": 1
						},
						"autozoom": features.length > 1
					}
				}
				this.$store.dispatch('set_map_itineraire', {geojson, result: {
						vehicule: this.vehicule,
						depart: this.depart,
						arrivee: this.arrivee,
						type: this.type,
						itineraire: this.itineraire
					}
				})
			}else{
				this.$store.dispatch('set_map_itineraire', {geojson: null, result: null})
			}
		},
		getKm(km){
			let digits = 2
			if(km > 3) digits = 1
			if(km > 20) digits = 0
			return new Intl.NumberFormat('fr-FR', {maximumFractionDigits: digits}).format(km) + ' km'
		},
		getTime(secondes){
			let heures = Math.floor(secondes/60/60);
			let secondes_restantes = secondes - heures*60*60;
			let minutes = Math.floor(secondes_restantes/60);
			let affichage = []
			if(heures) affichage.push(heures+' h')
			if(heures || minutes) affichage.push(minutes+' min')
			return affichage.join(' ');
		},
		reset(){
			this.vehicule   = 'truck'
			this.depart     = null
			this.arrivee    = null
			this.type       = 'fast'
			this.itineraire = null
			this.updateGeoJson()
			this.$refs.field_address_1.resetAddress()
			this.$refs.field_address_2.resetAddress()
		},
		getCo2(metres){
			let co2 = metres*(this.vehicule == 'truck' ? 0.00135 : 0.00015)
			return new Intl.NumberFormat('fr-FR', {maximumFractionDigits: 0}).format(co2)
		}
	},
	computed:{
		isOk(){
			return this.vehicule && this.depart && this.arrivee && this.type
		}
	},
	components:{
		FieldAdresse,
		ModaleGauche
	}
}